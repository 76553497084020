<template>
    <Layout>
        <div>
            <h1 class="text-h4 mb-8">
                Ustawienia
                <p class="text-body-2 grey--text">wybranych funkcjonalności aplikacji</p>
            </h1>

            <v-sheet
                rounded
                class="pa-4"
                style="max-width: 550px; border: 1px solid #f3f3f3"
            >
                <v-fade-transition mode="out-in">
                    <div v-if="settings">
                        <v-list-item
                            dense
                            class="grey lighten-5"
                        >
                            <v-list-item-content> Połączenie webhook z Vcita: </v-list-item-content>
                            <v-list-item-content class="align-end font-weight-medium">
                                <div class="d-flex justify-end px-2">
                                    <v-switch
                                        inset
                                        true-value="1"
                                        false-value="0"
                                        v-model="settings.payments_enable_webhooks"
                                        color="primary"
                                    />
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            dense
                            class="grey lighten-5"
                        >
                            <v-list-item-content>
                                Wystawiaj automatycznie faktury dla płatności o statusie 'paid':
                            </v-list-item-content>
                            <v-list-item-content class="align-end font-weight-medium">
                                <div class="d-flex justify-end px-2">
                                    <v-switch
                                        inset
                                        true-value="1"
                                        false-value="0"
                                        v-model="settings.payments_enable_autoinvoicing"
                                        color="primary"
                                    />
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            dense
                            class="grey lighten-5"
                        >
                            <v-list-item-content>
                                Wystawiaj automatycznie korekty w momencie otrzymania od Vcita
                                informacji o zwrocie:
                            </v-list-item-content>
                            <v-list-item-content class="align-end font-weight-medium">
                                <div class="d-flex justify-end px-2">
                                    <v-switch
                                        inset
                                        true-value="1"
                                        false-value="0"
                                        v-model="settings.payments_enable_autocorrecting"
                                        color="primary"
                                    />
                                </div>
                            </v-list-item-content>
                        </v-list-item>

                        <v-btn
                            color="success"
                            class="mt-4"
                            :loading="saving_changes"
                            @click="saveChanges"
                        >
                            Zapisz zmiany
                        </v-btn>
                    </div>
                    <div
                        class="pa-8 text-center"
                        v-else
                    >
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            width="3"
                        ></v-progress-circular>
                    </div>
                </v-fade-transition>
            </v-sheet>
        </div>
    </Layout>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            settings: null,
            saving_changes: false
        };
    },

    methods: {
        async saveChanges() {
            if (this.saving_changes) return;
            this.saving_changes = true;

            try {
                await this.$axios.$put("/settings", this.settings);

                this.$message({
                    type: "success",
                    msg: "Zmiany zostały zapisane"
                });
            } catch (err) {
                console.error(err);
            }

            this.saving_changes = false;
        }
    },

    async mounted() {
        if (!this.$store.state.initial_layout_set) return;

        try {
            const r = await this.$axios.$get("/settings");
            // console.log(r);
            this.settings = r.settings;
        } catch (err) {
            console.error(err);
        }
    },

    metaInfo: {
        title: "Ustawienia"
    }
};
</script>
